import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OttService {

  constructor(
    private http: HttpClient
    ) { 

    }

    getChannels(){
      return this.http.get<any>("https://api.thomas-talk.me/GetChanels/FibexPlay");
    }
}