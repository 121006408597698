import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { ModalDismissReasons, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import swal from 'sweetalert2';

@Component({
  selector: "app-form-contact",
  templateUrl: "./form-contact.component.html",
  styleUrls: ["./form-contact.component.css"],
})
export class FormContactComponent implements OnInit {
  public regexEmail: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public regexNumb: RegExp = /^[0-9]*$/;
  contactForm: FormGroup;
  closeResult = "";
  @Input() data: any = '';
  title: any;
  dataPromotion: any
  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.contactForm = this.initForm();

    this.dataPromotion = this.data.dataPromo

  }

  initForm(): FormGroup {
    return this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.regexEmail)]],
      name: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      phone: ["", [Validators.required, Validators.minLength(11), Validators.pattern(this.regexNumb), this.initWith],],
      address: ["", [Validators.required]],
      city: ["", [Validators.required]],
    });
  }

  get email() { return this.contactForm.get("email"); }
  get name() { return this.contactForm.get("name"); }
  get lastname() { return this.contactForm.get("lastname"); }
  get phone() { return this.contactForm.get("phone"); }
  get address() { return this.contactForm.get("address"); }
  get city() { return this.contactForm.get("city"); }

  async send() {

    const formData = [
      { property: "email", value: this.contactForm.get("email").value },
      { property: 'work_email', value: this.contactForm.get("email").value },
      { property: 'correo_electrnico', value: this.contactForm.get("email").value },
      { property: "firstname", value: this.contactForm.get("name").value },
      { property: "lastname", value: this.contactForm.get("lastname").value, },
      { property: "website", value: "https://fibextelecom.net/" },
      { property: "company", value: "Fibextelecom" },
      { property: "phone", value: this.contactForm.get("phone").value },
      { property: 'mobilephone', value: this.contactForm.get("phone").value },
      { property: "fuente_lead", value: "FACEBOOK DM" },
      { property: 'hs_analytics_source', value: 'ORGANIC_SEARCH' },
      // { property: 'hs_analytics_last_url', value: document.location.href },
      // { property: 'hs_analytics_source_data_1', value: 'WEB SITE' },
      { property: 'country', value: 'Venezuela' },
      { property: "message", value: `Plan: ${this.dataPromotion} .- Nombre Completo: ${this.contactForm.get("name").value} ${this.contactForm.get("lastname").value || ""} .- Correo: ${this.contactForm.get("email").value} .- Ciudad: ${this.contactForm.get("city").value} .- Dirección: ${this.contactForm.get("address").value} .- Teléfono: ${this.contactForm.get("phone").value}`, },
      { property: "address", value: this.contactForm.get("address").value },
      { property: 'hs_timezone', value: 'america_slash_caracas' },
      { property: "city", value: this.contactForm.get("city").value },
      { property: 'state', value: 'MA' }
    ];

    if (formData[11].value >= 501) {
      formData[11].value = formData[7].value.substring(0, 500)
    }


    const query = formData.reduce((str, item) => str + `${item.property}=${item.value}&`, "").slice(0, -1)

    try {
      const request: any = await this.http.get(`${environment.hotspotApi}?${query}`).toPromise();

      if (request.error) {
        swal.fire({
          title: 'Correo electrónico ya registrado',
          text: 'Por favor, intente llenar nuevamente el formulario utilizando otro correo electrónico',
          timer: 3000,
        });
        this.activeModal.dismiss();
        return {
          error: true,
          data: null,
          message: request.errors[0].message,
        };
      } else {
        swal.fire({
          title: 'Formulario enviado <br> Gracias por su tiempo',
          timer: 3000
        });
        this.activeModal.dismiss();
        return {
          error: false,
          data: request,
        };
      }
    } catch (error) {
      return {
        error: true,
        data: null,
        message: error,
      };
    }
  }

  dismiss() {
    swal.fire({
      title: 'Formulario enviado <br> Gracias por su tiempo',
      timer: 3000
    });
    this.activeModal.dismiss();
  }

  initWith(control: AbstractControl) {
    let numb = false
    const initWith = [
      "0416",
      "0426",
      "0412",
      "0414",
      "0424",
      "416",
      "426",
      "412",
      "414",
      "424",
      "58416",
      "58426",
      "58412",
      "58414",
      "58424",
    ];

    for (let i = 0; i < initWith.length; i++) {
      if (control.value.startsWith(initWith[i])) {
        numb = true;
      }
    }

    if (!numb) {
      return { invalidInit: true }
    }
    return null
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
