import { Component, Input, OnInit } from "@angular/core";
/* import logoImg from '../../../../assets/images/Fibex/Fibex.png' */
/* '../../../../assets/images/Fibex/Fibex.png'; */
import { ConsultasMapsService } from "src/app/core/services/consultas-map.service";

@Component({
  selector: "app-footer-style1",
  templateUrl: "./footer-style1.component.html",
  styleUrls: ["./footer-style1.component.css"],
})
export class FooterStyle1Component implements OnInit {
  /* public navLogo: any = logoImg; */
  fullyear = new Date().getFullYear()
  @Input() classes:string = '';
  @Input() sectionImage?:string = 'assets/images/Fibex/Fibex.png';

  constructor(public ConsultaS: ConsultasMapsService) {
    this.ConsultaS.DatosMapas.subscribe((Datos: any) => {
      this.footerText[0].text = Datos.length > 0 ? Datos[0].Direccion : "Centro Comercial Vía Veneto. Av. 168 Salvador Feo La Cruz Este-Oeste, Naguanagua 2005. Local F-5 Nivel Fiorentina Carabobo, Venezuela";
      /* this.footerTiming[0].text =
        Datos.Horario || "Lunes - Viernes: 8:00 am - 4:00 pm"; */
      /*  this.footerTiming[0].text = "Lunes - Viernes: 8:00am - 5:00pm";
       this.footerTiming[0].text2 = "Sábados: 8:00am - 2:00pm";
       this.footerTiming[0].text3 = "Redes sociales: 8:00am - 2:00pm";
       this.footerTiming[0].text4 = "Call center: 8:00am - 2:00pm"; */
    });
  }

  ngOnInit() {
   }

  public footerText: any = [
    {
      icon: true,
      iconClass: "ion-ios-location-outline",
      text: "Centro Comercial Vía Veneto. Av. 168 Salvador Feo La Cruz Este-Oeste, Naguanagua 2005. Local F-5 Nivel Fiorentina Carabobo, Venezuela",
    },
    {
        icon: true,
        iconClass: "ion-ios-telephone-outline",
        textB: "04120202020",
    },
    {
      icon: true,
      iconClass: "",
      textC: "02123108300",
    },
    // {
    //   icon: true,
    //   iconClass: "ion-ios-email-outline",
    //   textC: "hogar@fibextelecom.net",
    // },
  ];

  public footerHome: any = [
    {
      section: [
        {
          href: "/Empresa",
          title: "Empresa",
        },
        {
          href: "/Pyme",
          title: "PYME'S",
        },
        {
          href: "https://reporte.fibextelecom.net/",
          target: '_blank',
          title: "Reportar Avería",
        },
        {
          href: "",
          target: '_blank',
          title: "Test de Velocidad",
        }
      ],
    },
    /*  {
      section: [
        {
          href: 'javascript:void(0)',
          title: 'FAQs'
        },
        {
          href: 'javascript:void(0)',
          title: 'Blog'
        },
        {
          href: 'javascript:void(0)',
          title: 'Our services'
        },
        {
          href: 'javascript:void(0)',
          title: 'Contact us'
        }
      ]
    } */
  ];

  public footerTiming: any = [
    {
      icon: false,
      /* text: "Lunes - Viernes: 8:00 am - 4:00 pm", */
      texta: "Sucursales:",
      text1: "Lunes - Viernes: 8:00 a.m. - 5:00 p.m.",
      text2: "Sábados: 8:00 a.m. - 2:00 p.m.",
      text3: "Nota de horario de caja: 8:00 a.m. - 4:45 p.m.",
    },
    {
      icon: false,
      text5: 'Redes Sociales:',
      text6: 'Lunes - Sábados: 8:00 a.m. - 8:00 p.m.'
    }
  ];
  // @ts-ignore
  public footerTextBottom = `© ${this.fullyear} FIBEX TELECOM.`;

  public socialNavItems: any = [
    {
      href: "https://api.whatsapp.com/send/?phone=584120202020&text&app_absent=0",
      icon: '<i class="fab fa-whatsapp"></i>',
    },
    {
      href: "https://www.instagram.com/accounts/login/?next=/fibextelecomve/",
      icon: '<i class="fab fa-instagram"></i>',
    },
    {
      href: "https://www.facebook.com/fibextelecomve",
      icon: '<i class="fab fa-facebook-f"></i>',
    },
    {
      href: "https://www.tiktok.com/@fibextelecomve",
      icon: '<i class="fab fa-tiktok"></i>',
    },
    {
      href: "https://www.linkedin.com/company/fibex-telecom",
      icon: '<i class="fab fa-linkedin"></i>',
    },
  ];

  ChangeData() { }

  NewWindows(url: any) {
    window.open(url);
  }

  goToReport() {
    window.open("https://reporte.fibextelecom.net/"); return false;
  }
}
