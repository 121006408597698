import {Component, Input, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-card-style9',
  templateUrl: './card-style9.component.html',
  styleUrls: ['./card-style9.component.css']
})
export class CardStyle9Component implements OnInit {
  @Input() data:any;

  public fireStep: any;
  constructor() {
    this.fireStep = [
      {
        id:1,
        icon: '',
        title: 'PASO 1',
        description: 'Entra en Configuración > Mi Fire TV > Opciones para desarrolladores > Activa las opciones de Depurado ADB y Apps de Origen desconocido',
        buttonLabel: 'DESCARGAR'
      },
      {
        id:2,
        icon: '',
        title: 'PASO 2',
        description: 'Entra en el buscador del Fire TV y escribe DOWNLOADER, descargue, instale e inicie la aplicación aceptando las condiciones',
        buttonLabel: 'DESCARGAR'
      },
      {
        id:3,
        icon: '',
        title: 'PASO 3',
        description: 'En la barra de busqueda escriba: https://app.fibexplay.tv/ presione GO para iniciar la descarga del aplicativo, instale y acepte las condiciones',
        buttonLabel: 'DESCARGAR'
      },
    ];
   }

  @Input() title: string;
  @Input() description: string;

  ngOnInit() {
  }

  List: any[] = [
    { title: 'Descargar e instalar Downloader', image: './assets/images/Fibex/step1.png', description: 'Si aun no tiene instalada la App Downloader en su TV Box, diríjase a la Play Store, busque «Downloader», proceda a instalar y abrir.' },
    { title: 'Copiar link de descarga de Fibex Play', image: './assets/images/Fibex/step2.png', description: 'En «Downloader» escribiremos el link de descarga de Fibex Play tal como se señala en la imagen y hacemos clic en «GO» para iniciar la descarga.' },
    { title: 'Instalar App Fibex Play', image: './assets/images/Fibex/step3.png', description: 'Al finalizar la descarga tenemos que seleccionar la opción de «INSTALL» y seguir los pasos para que se realice la instalación.' },
    { title: 'Instalación final de la App en TV Box', image: './assets/images/Fibex/step4.png', description: 'Se confirma la instalación de la aplicación y esperamos unos segundos para que finalice. Hasta aquí se realiza el proceso para instalar Fibex Play.' },
    { title: 'Tener Fibex Play en TV Box', image: './assets/images/Fibex/step5.png', description: 'Finalmente, buscamos la aplicación en nuestro menú de apps del dispositivo para empezar a disfrutar del contenido.' },
  ];

}
