import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderStyle1Component } from "./components/partials/headers/header-style1/header-style1.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { RecaptchaModule } from "ng-recaptcha";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CardStyle7Component } from "./components/card/card-style7/card-style7.component";
import { FancyCardComponent } from "./components/card/fancy-card/fancy-card.component";
import { SectionStyle2Component } from "./components/sections/section-style2/section-style2.component";
import { FooterMainComponent } from "./components/partials/footers/footer-main/footer-main.component";
import { FooterLogoComponent } from "./components/footer-components/footer-logo/footer-logo.component";
import { FooterAddressComponent } from "./components/footer-components/footer-address/footer-address.component";
import { ParallaxHeroStyle1Component } from "./components/parallax-hero-style1/parallax-hero-style1.component";
import { CardStyle1Component } from "./components/card/card-style1/card-style1.component";
import { PricingCardStyle2Component } from "./components/card/pricing-card-style2/pricing-card-style2.component";
import { FormContactComponent } from "./components/modal/form-contact/form-contact.component";
import { ScrollTopComponent } from "./components/scroll-top/scroll-top.component";
import { ColorCustomizerComponent } from "./components/color-customizer/color-customizer.component";
import { ChannelListComponent } from "./components/modal/channel-list/channel-list.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { RootModule } from "./root/root.module";
import { CardStyle5Component } from "./components/card/card-style5/card-style5.component";
import { CardStyle6Component } from "./components/card/card-style6/card-style6.component";
import { ClientCarouselComponent } from "./components/client-carousel/client-carousel.component";
import { CardStyle8Component } from "./components/card/card-style8/card-style8.component";
import { FooterStyle1Component } from "./components/partials/footers/footer-style1/footer-style1.component";
import { CardStyle9Component } from "./components/card/card-style9/card-style9.component";
import { FooterStyle2Component } from "./components/partials/footers/footer-style2/footer-style2.component";
import { SectionTitleComponent } from "./components/sections/section-title/section-title.component";
import { VTwoPricingCardStyle1Component } from './components/card/v-two-pricing-card-style1/v-two-pricing-card-style1.component';
import { VTwoTestimonialCarousel2Component } from './components/v-two-testimonial-carousel2/v-two-testimonial-carousel2.component';
import { VTwoTestimonialComponent } from './components/v-two-testimonial/v-two-testimonial.component';
import { VTwoSectionTitleComponent } from './components/sections/v-two-section-title/v-two-section-title.component';
import { FeatureCardComponent } from './components/card/feature-card/feature-card.component';
import { SectionIconTitleComponent } from './components/sections/section-icon-title/section-icon-title.component';
import { Mundialqatar2022Component } from './components/modal/mundialqatar2022/mundialqatar2022.component';
import { FootballPoolsComponent } from './components/football-pools/football-pools.component';
import { PromoSharedComponent } from './components/promo-shared/promo-shared.component';
import { BannerModalComponent } from './components/banner-modal/banner-modal.component';
import { MapComponent } from './components/map/map.component';
import { PipesModule } from "./pipes/pipes.module";
import { CardStyle10Component } from './components/card/card-style10/card-style10.component';
import { AccordionComponent } from "./components/accordion/accordion.component";
import { SocialMediaComponent } from "./components/social-media/social-media.component";
import { ClubFibexModule } from "../modules/club-fibex/club-fibex.module";
import { ChatbotComponent } from "./components/chatbot/chatbot.component";
import { VideoModalComponent } from "./components/modal/video-modal/video-modal.component";
import { CardStyle11Component } from './components/card/card-style11/card-style11.component';
import { WallpaperComponent } from './components/swipers/wallpaper/wallpaper.component';
import { ChatIframeComponent } from './components/chat-iframe/chat-iframe.component';

const shared = [
  HeaderStyle1Component,
    CardStyle1Component,
    CardStyle7Component,
    FancyCardComponent,
    SectionStyle2Component,
    FooterMainComponent,
    FooterLogoComponent,
    FooterAddressComponent,
    ParallaxHeroStyle1Component,
    PricingCardStyle2Component,
    FormContactComponent,
    ScrollTopComponent,
    ColorCustomizerComponent,
    ChannelListComponent,
    ContactFormComponent,
    SectionTitleComponent,
    CardStyle5Component,
    CardStyle6Component,
    ClientCarouselComponent,
    CardStyle8Component,
    FooterStyle1Component,
    CardStyle9Component,
    FooterStyle2Component,
    VTwoTestimonialCarousel2Component,
    VTwoTestimonialComponent,
    VTwoSectionTitleComponent,
    VTwoPricingCardStyle1Component,
    FeatureCardComponent,
    SectionIconTitleComponent,
    Mundialqatar2022Component,
    FootballPoolsComponent,
    PromoSharedComponent,
    BannerModalComponent,
    MapComponent,
    CardStyle10Component,
    AccordionComponent,
    SocialMediaComponent,
    ChatbotComponent,
    VideoModalComponent,
    CardStyle11Component,
    WallpaperComponent,
    ChatIframeComponent
]
@NgModule({
  declarations: [ ...shared ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    RootModule,
    PipesModule
  ],
  exports: [ ...shared ],
  entryComponents: [Mundialqatar2022Component,FootballPoolsComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
