export const clientsPaths = [
  {
          
          title: 'Contacto',
          link:'contacto',
          src:'https://share.hsforms.com/1hOfcaYkvRQGbEg-_VDSk2Ac0zs0',
          height:'64rem'
  },
  {
          
          title: 'Valencia Camión Amarillo',
          link:'valencia-camion-amarillo',
          src:'https://share.hsforms.com/1pEEt-CpnRvi9lqj-etov-Qc0zs0',
          height:'64rem'
  },
  {
          
          title: 'La Bottega',
          link:'la-bottega',
          src:'https://share.hsforms.com/1EvHw11PyRuGS7d2bmi7OhAc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Margarita',
          link:'margarita',
          src:'https://share.hsforms.com/1hZwuu_8ORKagQhAOM59RXAc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Nirgua',
          link:'nirgua',
          src:'https://share.hsforms.com/1aW2VhWL-TKiNAi-afrrm5gc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Puerto Cabello',
          link:'puerto-cabello',
          src:'https://share.hsforms.com/16RamwIFISgeUHBcOsnz6-gc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Maturín',
          link:'maturin',
          src:'https://share.hsforms.com/1zJ0a2YW9SwuqvR01S9S61Ac0zs0',
          height:'64rem'
  },
  {
          
          title: 'La Zona',
          link:'la-zona',
          src:'https://share.hsforms.com/1h-xq_aO7R5iOxCxmmY1ENgc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Cuadro Gastronómico',
          link:'cuadro-gastronomico',
          src:'https://share.hsforms.com/1Fo3UvFfDQuqPI-3DiPa_wwc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Isla La Cumaca',
          link:'isla-la-cumaca',
          src:'https://share.hsforms.com/1pjuSfqbJQsmq9M_hXzXp6Ac0zs0',
          height:'64rem'
  },
  {
          
          title: 'Turmero',
          link:'turmero',
          src:'https://share.hsforms.com/1VefamQVmSLSe_bE9bvxQvAc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Isabélica',
          link:'isabelica',
          src:'https://share.hsforms.com/1VNsP9Vt2RAiVskt1nSJ0BAc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Sandy',
          link:'sandy',
          src:'https://share.hsforms.com/1pNtpRevKSfyzejoMgBwCzQc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Notitarde',
          link:'notitarde',
          src:'https://share.hsforms.com/1fdMen1PeRW-0UMyxiEYRiQc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Radio Vargas',
          link:'radio-vargas',
          src:'https://share.hsforms.com/1oZRknJ6URtuD9hCU6BH2Owc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Playera',
          link:'playera',
          src:'https://share.hsforms.com/1M_YWbtLySs25J07o3ymtTAc0zs0',
          height:'64rem'
  },
  {
          
          title: '¡Contrata ya!',
          link:'links',
          src:'https://share.hsforms.com/1NGpvu2y5Qkm00k5Qxfp9pwc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Website',
          link:'website',
          src:'https://share.hsforms.com/1adnHHdBcQ0mt-QuVZDaGiwc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Ads Regiones',
          link:'ads-regiones',
          src:'https://share.hsforms.com/1LxZQ8bH6TTGVwpC4xPFchQc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Ads Maracay',
          link:'ads-maracay',
          src:'https://share.hsforms.com/1eDS8uYBJRYuHlLaabBWPWQc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Ads Guigue',
          link:'ads-guigue',
          src:'https://share.hsforms.com/1jm1G3CTkToSmYTin9GsuIwc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Camión Caracas',
          link:'camion-caracas',
          src:'https://share.hsforms.com/14ez_JdFZSFamcA9iJkoSBgc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Hookah Bar',
          link:'hookah-bar',
          src:'https://share.hsforms.com/1YbxFa5qgRuKMaQMxJ0W6Fgc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Hakobar',
          link:'hakobar',
          src:'https://share.hsforms.com/1YbxFa5qgRuKMaQMxJ0W6Fgc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Reconocimiento',
          link:'reconocimiento',
          src:'https://share.hsforms.com/18anwxexnRoSVsOpc1ntMxQc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Instagram DM',
          link:'instagram-dm',
          src:'https://share.hsforms.com/1DZibs-6rTQCxvAimnUVu-Qc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Facebook DM',
          link:'facebook-dm',
          src:'https://share.hsforms.com/1mfw-M_j5Tr2RH8Aioa135Ac0zs0',
          height:'64rem'
  },
  {
          
          title: 'Mural Metrópolis',
          link:'mural-metropolis',
          src:'https://share.hsforms.com/1zegStQstR7-CtI34uuuwcQc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Concurso',
          link:'concurso',
          src:'https://share.hsforms.com/1w5DCvdinTDmkuktLMp1ZWAc0zs0',
          height:'64rem'
  },
  {
          
          title: 'Compras',
          link:'compras',
          src:'https://docs.google.com/forms/d/e/1FAIpQLSe153mNQO1Lzbqf3MkU0hzsqCdzpd8b0CUbQ42oFr-OXy0AhQ/viewform',
          height:'118rem'
  },
  {
          
          title: 'Masivo Tinaquillo',
          link:'masivotinaquillo',
          src:'https://share.hsforms.com/1Kxoot4YmS1OkVTVe1DuIrwc0zs0',
          height:'46rem'
  },
  {
          
          title: 'Factura Fiscal',
          link:'comunicadofacturafiscal022023',
          src:'https://manuales.fibextelecom.net/COMUNICADO_FACTURA_DIGITAL_0802203.pdf#toolbar=0',
          height:'48rem'
  },
  {          
          title: 'Feria de empleo',
          link:'eventoreclutamiento',
          src:'https://share.hsforms.com/1gLw2JluRS6uK3UqXFPp2TAc0zs0',
          height:'48rem'
  },
  {          
          title: 'Promo especial cojedes',
          link:'masivocojedes',
          src:'https://share.hsforms.com/1nKXKjdZcQCyVtmTkcPMpkgc0zs0',
          height:'48rem'
  },
  {          
          title: 'Proyecto conatel servicio universal',
          link:'serviciouniversal',
          src:'https://share.hsforms.com/1j2Ml441iTBSvGRCdQNI8LQc0zs0',
          height:'48rem'
  },
  {
          title: 'Encuesta Bowling Ping 5',
          link:'encuesta-bowling-ping-5',
          src:'https://docs.google.com/forms/d/e/1FAIpQLSfWdUrm5FOWpxpFcFn1bxCSNIBhtFEJErVDKn94rEwtB_JyhQ/formResponse',
          height:''
  },
  {
    title: 'CONFIRMACIÓN INVITACIÓN PREMIER - CLUB FIBEX',
    link:'gf-premier-club-fibex',
    src:'https://docs.google.com/forms/d/e/1FAIpQLSfjMgWohlXt47jHRQ0pOWEpdYJgwdf5F3kcI-s9Nv2wB-yR1g/formResponse',
    height:''
  },
  {
    title: 'Promoción especial Fibex Telecom | Cable Hogar',
    link:'gf-fibex-telecom-cable-hogar',
    src:'https://docs.google.com/forms/d/e/1FAIpQLScYv4ete7v0D-gfDt1QVqXvy_qjVxvGtWdA0XRTtk74GPpP4g/formResponse',
    height:''
  },
  {
    title: 'Thomas',
    link:'gf-thomas',
    src:'https://docs.google.com/forms/d/e/1FAIpQLScYv4ete7v0D-gfDt1QVqXvy_qjVxvGtWdA0XRTtk74GPpP4g/formResponse',
    height:''
  },
  {
    title: 'Fibex',
    link:'gf-fibex',
    src:'https://docs.google.com/forms/d/e/1FAIpQLScYv4ete7v0D-gfDt1QVqXvy_qjVxvGtWdA0XRTtk74GPpP4g/formResponse',
    height:''
  },
  {
    title: 'Distelnet',
    link:'gf-distelnet',
    src:'https://docs.google.com/forms/d/e/1FAIpQLScYv4ete7v0D-gfDt1QVqXvy_qjVxvGtWdA0XRTtk74GPpP4g/formResponse',
    height:''
  },
];
