import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConsultasMapsService {
  DatosMapas: EventEmitter<any> = new EventEmitter();

  constructor() { }

  DatosSede(data: any) {
    // console.log(data)
    return this.DatosMapas.emit(data);
  }
}
