<!-- <section>
    <swiper-container id="swiper" class="mySwiper" navigation="true" loop="false" centered-slides="true"
        pagination-dynamic-bullets="true" pagination="false" autoplay-delay="5000"
        autoplay-disable-on-interaction="false" #swiper>

        <ng-container>
            <swiper-slide *ngFor="let wallpaper of wallpapers">
                <img class="banners" [src]="isMobile ? wallpaper.imgMobile : wallpaper.imgDesktop" alt="Banner" (click)="emitRedirection()">
            </swiper-slide>
        </ng-container>

    </swiper-container>
    <span class="progressbar-container">
        <span class="progressbar" *ngFor="let progressbar of wallpapers; let i = index;" (click)="goToSlide(i)">
            <div [id]="'bar-' + i" [class]="'bar '+ progressbar.category"></div>
        </span>
    </span>
</section> -->

<div class="hero_main_container">
    <app-parallax-hero-style1 ids="iq-home"  
        [bgVideo]="isMobile ? null : 'https://cms.fibextelecom.info/uploads/Banner_PRINCIPAL_8c073c2b37.mp4'"
        [bgImage]="isMobile ? 'https://cms.fibextelecom.info/uploads/Banner_HOGAR_3ebba30d8e.jpg' : null"
        classNames="iq-banner-07 overview-block-ptb iq-bg-over iq-parallax" 
        data-jarallax='{"speed": 0.6}'>
        <div class="container">
            <div class="banner-text">
                <div class="row">
                    <div class="hero_title col-lg-12">
                        <div class="content-container">
                            <h1 class="iq-font-black iq-tw-5">
                                <span class="responsive-text">
                                    <b>Internet</b> Fibra Óptica + TV +
    
                                </span>
                            </h1>
                            <img class="img_hero" src="https://cms.fibextelecom.info/uploads/Logo_club_fibex_banner_1db8551f8c.png" alt="Slogan">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-parallax-hero-style1>

    <div *ngIf="!isMobile" class="container">
        <div class="banner-text">
            <div class="row">
                <div class="hero_title col-lg-12">
                    <div class="content-container">
                        <h1 class="iq-font-black iq-tw-5">
                            <span class="responsive-text">
                                <b>Internet</b> Fibra Óptica + TV +

                            </span>
                        </h1>
                        <img class="img_hero" src="https://cms.fibextelecom.info/uploads/Logo_club_fibex_banner_1db8551f8c.png" alt="Slogan">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
    <!-- BACKGROUND PARTICLES -->


