
export const environment = {
  production: true,
  
  // ! Url Api
  hotspotApi: 'https://hotspot.thomas-talk.me/data-microtik/',
  Apithomas: "https://api.thomas-talk.me/",
  thomas_crm:"https://backend.thomas-talk.me/api/v1",
  urlApiTls: 'https://apitls.thomas-talk.me',

  // ! Key - Lic -Tokens
  apiKeySsl: '4p1_[51t3m4s]_f186@th0m4s',
  key_enc: 'T0rNaDoK4tr1Na?RTgcNmhKU=',

  // ! Method - Platform - db
  metodoSucursales: 'SucursalesAPP',
  platform: 'Web Fibex',
  //! ChatBot
  botAPI: "https://bot.thomas-talk.me/bot/tell-me",

  //! dbFull
  db:'thomas_cobertura',
  dbFull: 'https://dbfull.thomas-talk.me/api',
  dbFullCreate: 'create-info',
  dbFullFindAny: 'find-any-info',
  tokenAuthPlatform: 'h34BEVSzAijWRFeXBmRGA8FfarVHVLHZo_mfmS7c7TFUjlpMt0LFiI-NKy1tr89pJwpXoPiL2BemcwuDKIuJzeqii-eiZXilqyQYOcm_o1_SE29K5J76E1G-ZY--m0QHdSXvLrR2-dK6I8S7x24pbyzixoSgldSYCulhmooI5Ls0jAr4HS6a',
  authorizationTKN:'Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB',

  //!Club Fibex
  CFdb: 'thomas_clubfibex',
  CFProducts: 'cb_EmpresaProductos',
  clubFibexBD: 'thomas_clubfibex',
  //clubFibexBD: 'thomas_sandbox_clubfibex',

  //! Backend Thomas
  backendThomas: 'https://backend.thomas-talk.me'
};