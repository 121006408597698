import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UrlPipe } from './url.pipe';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [UrlPipe],
  exports: [
      UrlPipe
  ]
})
export class PipesModule {}
