import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-v-two-pricing-card-style1',
  templateUrl: './v-two-pricing-card-style1.component.html',
  styleUrls: ['./v-two-pricing-card-style1.component.css']
})
export class VTwoPricingCardStyle1Component implements OnInit {
 /*  fliped: boolean = false;

  canales = [
    { src: './assets/images/channels/88estereo.png' },
    { src: './assets/images/channels/247.png' },
    { src: './assets/images/channels/accnespn.png' },
    { src: './assets/images/channels/meridiano.png' },
    { src: './assets/images/channels/adn.png'},
    { src: './assets/images/channels/senalcolombia.png' },
    { src: './assets/images/channels/adsports.png' },
    { src: './assets/images/channels/afrobeats.png' },
    { src: './assets/images/channels/animakids.png' },
    { src: './assets/images/channels/animalplanet.png' },
    { src: './assets/images/channels/antv.png' },
    { src: './assets/images/channels/cgtn.png' },
    { src: './assets/images/channels/cinecanal.png' },
    { src: './assets/images/channels/cinelatino.png' },
    { src: './assets/images/channels/cineromantico.png' },
    { src: './assets/images/channels/clarosports.png' },
    { src: './assets/images/channels/cnn.png' },
    { src: './assets/images/channels/depelicula.png' },
    { src: './assets/images/channels/discovery.png' },
    { src: './assets/images/channels/discoverykids.png' },
    { src: './assets/images/channels/discoveryscience.png'},
    { src: './assets/images/channels/distritocomedia.png' },
    { src: './assets/images/channels/espn2.png' },
    { src: './assets/images/channels/espn3.png' },
    { src: './assets/images/channels/estrella.png' },
    { src: './assets/images/channels/euronews.png' },
    { src: './assets/images/channels/fashiontv.png' },
    { src: './assets/images/channels/filmarts.png' },
    { src: './assets/images/channels/galiciatv.png' },
    { src: './assets/images/channels/glitz.png' },
    { src: './assets/images/channels/globovision.png' },
    { src: './assets/images/channels/golden.png' },
    { src: './assets/images/channels/goldenedge.png' },
    { src: './assets/images/channels/hgtv.png' },
    { src: './assets/images/channels/hispantv.png' },
    { src: './assets/images/channels/horrorfy.png' },
    { src: './assets/images/channels/hyh.png'},
    { src: './assets/images/channels/investigationdiscovery.png' },
    { src: './assets/images/channels/lasestrellas.png' },
    { src: './assets/images/channels/malaga.png' },
    { src: './assets/images/channels/maschic.png' },
    { src: './assets/images/channels/mtv.png' },
    { src: './assets/images/channels/much.png' },
    { src: './assets/images/channels/multipremier.png' },
    { src: './assets/images/channels/musictop.png' },
    { src: './assets/images/channels/nick.png' },
    { src: './assets/images/channels/panamericana.png' },
    { src: './assets/images/channels/realmadrid.png' },
    { src: './assets/images/channels/redbull.png' },
    { src: './assets/images/channels/redbulltv.png' },
    { src: './assets/images/channels/retroplus.png' },
    { src: './assets/images/channels/rumbatv.png' },
    { src: './assets/images/channels/tastemade.png'},
    { src: './assets/images/channels/tcm.png' },
    { src: './assets/images/channels/telehit.png' },
    { src: './assets/images/channels/telehiturbano.png' },
    { src: './assets/images/channels/teleritmo.png' },
    { src: './assets/images/channels/telesur.png' },
    { src: './assets/images/channels/televen.png' },
    { src: './assets/images/channels/tevex.png' },
    { src: './assets/images/channels/tlc.png' },
    { src: './assets/images/channels/tln.png' },
    { src: './assets/images/channels/tlnovelas.png' },
    { src: './assets/images/channels/tooncast.png' },
    { src: './assets/images/channels/topcine.png' },
    { src: './assets/images/channels/turbodiscovery.png' },
    { src: './assets/images/channels/tvagro.png' },
    { src: './assets/images/channels/tves.png' },
    { src: './assets/images/channels/valetv.png'},
    { src: './assets/images/channels/venevision.png' },
    { src: './assets/images/channels/vive.png' },
    { src: './assets/images/channels/vtv.png' },
    { src: './assets/images/channels/wowtv.png' },
  ] */
  constructor() { }

  @Input() className: string;

  ngOnInit() {
  }

  /* flip(){
    if (this.fliped) {
      this.fliped = false;
    }else{
      this.fliped = true;
    }
  } */
}
