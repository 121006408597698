<div style="background:url('./assets/images/Fibex/imgFooter.png');">
  <div class="modal-header border-0">
    <!-- activar por si se quiere utilizar el titulo de los botones de manera dinamica -->
    <!-- <h4 class="modal-title" id="modal-basic-title">{{this.title}}</h4> -->
    <img src="./assets/images/Fibex/Fibex.png" alt="" class="form-img">
    <a class="close-modal-button" type="button" (click)="activeModal.dismiss('Cross click')"><i style="color: #ddd5d5eb;" class="ion-ios-close"></i></a>
  </div>
  <h5 class="form-subtitle">Introduzca sus datos personales</h5>
  <h5 class="form-subtitle">En breve lo contactaremos</h5>
  <div class="modal-body">
    <form [formGroup]="contactForm" id="contact_form">
      <div class="mb-3">
        <!-- <label style="color: #000000;font-weight: normal;width: 100%;text-align: center;">Promoción: {{this.data.dataPromo}}</label> -->
        <!-- <br>
      <label style="color: #000000;font-weight: normal;width: 100%;text-align: center;">Mensualidad: {{this.data.plan[0].mPayment}} - Instalación: {{this.data.plan[0].installation}}</label> -->
        <div class="input-group">
          <input id="email" class="form-control" name="email" formControlName="email" type="email" required
            placeholder="Email*" style="border-radius:0.25rem"><br>
        </div>
        <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors?.pattern" class="alert-danger"
          style="text-align: center; top: 2px; position: relative;">
          Email inválido
        </div>
      </div>
      <div class="mb-3">
        <!-- <label for="name">Nombre: <span>*</span></label> -->
        <div class="input-group">
          <input id="name" class="form-control" name="name" formControlName="name" type="text" required
            placeholder="Nombre*">
        </div>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert-danger"
          style="text-align: center; top: 2px; position: relative;">
          Nombre inválido
        </div>
      </div>
      <div class="mb-3">
        <!-- <label for="lastname">Apellido: <span>*</span></label> -->
        <div class="input-group">
          <input id="lastname" class="form-control" name="lastname" formControlName="lastname" type="text" required
            placeholder="Apellido*">
        </div>
        <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="alert-danger"
          style="text-align: center; top: 2px; position: relative;">
          Apellido inválido
        </div>
      </div>
      <div class="mb-3">
        <!-- <label for="phone">Teléfono móvil: <span>*</span></label> -->
        <div class="input-group">
          <input id="phone" class="form-control" name="phone" formControlName="phone" type="text" required
            placeholder="Móvil de contacto*">
        </div>
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert-danger"
          style="text-align: center; top: 2px; position: relative;">
          Móvil de contacto inválido
        </div>
      </div>
      <div class="mb-3">
        <!-- <label for="address">Dirección: <span>*</span></label> -->
        <div class="input-group">
          <input id="address" class="form-control" name="address" formControlName="address" type="text" required
            placeholder="Dirección*">
        </div>
        <div *ngIf="address.invalid && (address.dirty || address.touched)" class="alert-danger"
          style="text-align: center; top: 2px; position: relative;">
          Dirección inválida
        </div>
      </div>
      <div class="mb-3">
        <!-- <label for="city">Ciudad: <span>*</span></label> -->
        <div class="input-group">
          <input id="city" class="form-control" name="city" formControlName="city" type="text" required
            placeholder="Ciudad*">
        </div>
        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="alert-danger"
          style="text-align: center; top: 2px; position: relative;">
          Ciudad inválida
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn btn-outline-dark" (click)="send()" [disabled]="!contactForm.valid"
      id="send">Enviar</button>
  </div>
</div>