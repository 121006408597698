import { AfterViewChecked, AfterViewInit, Component, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { trigger, style, animate, transition } from '@angular/animations';
import { Message, Button } from "src/app/core/constants/customer-chatbox";
// import $ from "jquery";
import { environment } from '../../../../environments/environment.prod';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { chatbot } from "./chatbot.metadata";
import { IncomingMessage } from "http";

/**
 * CustomerChatbotComponent Documentation (mainly the Message object and related functions):
 * 
 *  ********************************************************************************
 *  *  Message Box:                                          *  Related functions: *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.content: body of text                         *  formatString()     *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.form: forms the bot requests to be filled     *  createNewForm()    *
 *  *    --> formGroup                                       *  validateData()     *
 *  *    --> formControls                                    *                     *
 *  *      --> select: refers to <select>, contains the data *                     *
 *  *      --> (others)?: any other control can be added     *                     *
 *  *          through createNewForm() by setting a new case *                     *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.images: images to be shown                    *                     *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.buttons: buttons with choices                 *  selectOption()     *
 *  ********************************************************************************
 *  
 *  Role of this component: convert what BotResponse returns to a component the user can interact with
 *  
 */

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [
    trigger("chatbox-minimize", [
      transition(":enter", [
        style({ height: "0vh" }),
        animate("0.2s ease-out", style({ height: "70vh" }))
      ]),
      transition(":leave", [
        animate("0.2s ease-out", style({ height: "0vh" }))
      ])
    ])
  ]
})
export class ChatbotComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('chatboxBody', { static: false }) chatboxBody: any

  private botAPI = environment.botAPI;
  private sessionId = ""; // document.cookie and current datetime (current solution for getting an unique session id)

  // chatbox behaviour properties
  public showChatbox = false; // refers to the entire chatbox
  public isMinimized = false; // when true, only the .title is seen
  public isWaitingForMessage = false; // shows the "user is typing..." kind of animation while user is waiting for bot to answer

  public messages: Message[] = [];
  public messageInput: string = ""; // text message input
  private currentlyActiveForm: FormGroup | undefined;

  constructor(private http: HttpClient, private fb: FormBuilder) {}
  
  ngAfterViewChecked(): void {
    this.scrollToBottom(document.getElementById("chatbox-body"))
  }

  ngAfterViewInit(): void {
    this.sessionId = document.cookie + new Date();
    // this.chatInit()
    this.scrollToBottom(document.getElementById("chatbox-body"))
  }

  /**
   * Open the chatbox and initialize chat with it's first messages
   */

  scrollToBottom(element: HTMLElement | undefined) {
    if (element) element.scrollTo({top: element.scrollHeight, behavior: 'smooth'})
  }

  public chatInit(): void {
    this.showChatbox = true;

    // create automatic bot message from the client
    let welcomeMessage: Message = {
      from: "bot",
      content: "¡Hola! Soy tu Asistente Virtual y te doy la bienvenida a Club Fibex. Estoy para apoyarte en el uso de nuestra plataforma y sus funciones.",
      timeSent: this.getCurrentTime()
    };
    // instead of this.updateChat(), I add the message directly since I don't want to move the scrollbar when the chat is initializing
    this.messages.push(welcomeMessage);
    this.isWaitingForMessage = true; // start the loading animation

    // get first response from the bot API
    // this.getBotResponse("hola")
    // .then((botResponse: Message) => {
      this.isWaitingForMessage = false;
      // console.log(botResponse) // stop the loading animation
      // this.messages.push(botResponse);
      // this.messages.push({
      //   from: "bot",
      //   content: chatbot[0].message,
      //   timeSent: this.getCurrentTime()
      // })
    // })
    // .catch((errorMessage: Message) => {
    //   this.isWaitingForMessage = false;
    //   this.messages.push(errorMessage); // message.error should be defined here
    // });
  }

  /**
   * clear values from chat and essentially reset it
   */
  public destroyChat(): void {
    this.messages = [];
    this.showChatbox = false;
    this.isMinimized = false;
    this.sessionId = document.cookie + new Date(); // update session id
  }

  /**
   * When clicking the tray icon or the title, the chatbox will minimize
   */
  public toggleMinimize(): void {
    this.isMinimized = !this.isMinimized;
  }

  /**
   * When chatbox open animation starts, adjust chatbox's body scrollbar so it's stuck to the bottom
   */
   public afterChatboxIsOpen(): void {
    if (!this.isMinimized) { // when chatbox is being opened keep scrollbar stuck to the bottom
      let chatboxBody = document.getElementById("chatbox-body");
      if (chatboxBody) {
        chatboxBody.scrollTop = chatboxBody.scrollHeight;
      }
    }
  }

  /**
   * Send message to bot API and get response
   */
  public sendMessage(option: string, message: string): void {

    // let validationResult = this.validateData(); // should validate whenever there's a form present
    // if (validationResult.status === "INVALID") {
    //   this.updateChat({from: "bot", content: validationResult.message, timeSent: this.getCurrentTime()});
    //   return;
    // }

    let content = message;
    this.messageInput = ""; // clear <input />'s text
    this.updateChat({from: "user", content, timeSent: this.getCurrentTime()});
    this.isWaitingForMessage = true; // start the loading animation

    // wait for the bot's response
    setTimeout(() => { // add a small delay to show the user message before immediately getting the bot's response (happens in cases when the response comes too quick)
      this.isWaitingForMessage = false; // stop the loading animation
      this.updateChat(this.getBotResponse(option, message));
      
      // this.getBotResponse(option, message)
      // .then((botResponse: Message) => {
      //   this.isWaitingForMessage = false; // stop the loading animation
      //   this.updateChat(botResponse);
      // })
      // .catch((errorMessage: Message) => {
      //   this.isWaitingForMessage = false;
      //   this.updateChat(errorMessage); // message.error should be defined here
      // })
    }, 700);
    this.scrollToBottom(document.getElementById("chatbox-body"))
  }

  private getBotResponse(option: string, message: string) {

    let postBody = {
      "phone": this.sessionId,
      "message": message,
      "lic": "584120202020@c.us",
      "application": "web"
    };

    const incomingMessage = chatbot.find( (e, i) => i === +option)

    return {
      from: "bot",
      content: this.formatString(incomingMessage.message),
      timeSent: this.getCurrentTime()
    };

    // return new Promise((resolve, reject) => {
    //   this.http.post(this.botAPI, postBody).subscribe((data: BotResponse) => {
    //     if (data.status === "OK") {
    //       let incomingMessage = data.msg;
    //       if (!incomingMessage) {
    //         incomingMessage = "Disculpe no pude reconocer lo que quiso decir.";
    //       }

    //       let botMessage: Message = {
    //         from: "bot",
    //         content: this.formatString(incomingMessage),
    //         timeSent: this.getCurrentTime()
    //       };

    //       if (data.form != undefined) { // if data contains a form, create the means to display and manage it from the component
    //         botMessage.form = {
    //           formControls: data.form.formControls,
    //           formGroup: this.createNewForm(data.form.formControls)
    //         };
            
    //         this.currentlyActiveForm = botMessage.form.formGroup; // set the currently active form to do validations on
    //       }
  
    //       resolve(botMessage);
    //     } else {  
    //       reject({
    //         from: "bot",
    //         content: "Disculpe hubo error de conectividad con nuestro bot, intente comunicarse más tarde.",
    //         timeSent: this.getCurrentTime(),
    //         error: true
    //       } as Message);
    //     }
    //   }, (error) => {
    //     // for debugging I recomend logging this error inside this block
    //     reject({
    //       from: "bot",
    //       content: "Disculpe hubo un problema con nuestro bot, trataremos de solucionarlo lo mas pronto posible.",
    //       timeSent: this.getCurrentTime(),
    //       error: true
    //     } as Message);
    //   });
    // });
  }

  /**
   * Similar to sendMessage() but in the context of a <button> instead of <input />
   * @param option representation of <button> with the value it holds
   */
  public selectOption(option: Button): void {
    // update chat with user's choice
    this.updateChat({from: "user", content: option.value, timeSent: this.getCurrentTime()});
    this.isWaitingForMessage = true;

    // wait for the bot's response
    // this.getBotResponse(option.exec)
    // .then((botResponse: Message) => {
    //   this.isWaitingForMessage = false; // stop the loading animation
    //   this.updateChat(botResponse);
    // })
    // .catch((errorMessage: Message) => {
    //   this.isWaitingForMessage = false;
    //   this.updateChat(errorMessage); // message.error should be defined here
    // });
  }

  /**
   * Send to a server whether the customer was satisfied or not
   * @param isCustomerSatisfied represents the customer satisfaction with an answer the bot previously gave
   */
  public sendCustomerSatisfaction(isCustomerSatisfied: boolean) {
    // send the rating to the server for it to deal with
  }

  private updateChat(message: any): void {
    this.messages.push(message);

    // keep the scrollbar stuck to the bottom
    // let chatboxBody = $("#chatbox-body");
    // if (this.chatboxBody) {
    //   setTimeout(() => {
    //     this.chatboxBody.animate({scrollTop: this.chatboxBody.prop("scrollHeight")}, 500);
    //   }, 100);
    // }
  }

  /**
   * Create a form group given the passed form controls
   * @param formControls object with set of data which holds what type of control it is for (defined by the object keys) and what data to fill it with
   * @returns the form group corresponding to the bot's message 
   */
  private createNewForm(formControls: Message["form"]["formControls"]): FormGroup {
    let controls = {};
    for (let controlType in formControls) {
      switch (controlType) {
        case "select":
          controls[controlType] = ['', Validators.required]; // a <select> control should have this behaviour
          break;
        // add more cases here in line with Message.form.formControls property names when the need arises
      }
    }
    
    return this.fb.group(controls);
  }
  
  /**
   * custom validation when bot's response contains data that must be validated
   * @returns 
   */
  private validateData(): {status: string, message: string} {
    let validationResult = { status: "VALID", message: "" };
    if (!this.currentlyActiveForm) return validationResult; // if there's no form there's no need to validate

    if (!this.currentlyActiveForm.invalid) {
      this.currentlyActiveForm = undefined; // clear the currently active form since validation gave a green light
      return validationResult;
    }

    // if invalid is true, check for validation
    let status: any = this.currentlyActiveForm.status; // see FormControlStatus for values
    validationResult.status = status;
    validationResult.message = "Por favor seleccione una opción válida.";
    return validationResult;
  }

  /*--------------------- Helper functions --------------------*/
  /**
   * get current hours and minutes
   * @returns string time in the form of hh:mm
   */
  private getCurrentTime(): string {
    let date = new Date();
    return String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0");
  }

  /**
   * format the string into something html can read and interpret
   * @param str 
   * @returns formatted string with html
   */
  private formatString(str: string): string {
    str = str.replace(/\^\/.*$/g, ""); // remove the string that the server sends as ^{'object': 'foo'} (usually at the end)
    str = str.replace(/\n\n\n/g, "\n\n");
    str = str.replace(/\n\n/g, "\n");

    // detect bold text that comes in the form of *my text* and inject <b> tags
    let boldStringRegex = /\*.*\*/gi;
    let boldStringMatches = str.match(boldStringRegex);
    if (boldStringMatches) {
      for (let boldString of boldStringMatches) {
        let rawString = boldString.slice(1, -1); // remove the first and last character. Ex: *my text* -> my text
        str = str.replace(boldString, `<b>${rawString}</b>`);
      }
    }

    // detect links and add the <a> tag to them
    // let linkRegex = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    // let linkMatches = str.match(linkRegex);
    // if(linkMatches) {
    //   for (let link of linkMatches) {
    //     str = str.replace(link, `<a target="_blank" href="${link}">${link}</a>`);
    //   }
    // }

    // detect emails and add the <a> tag to them
    // let emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    // let emailMatches = str.match(emailRegex);
    // if(emailMatches) {
    //   for (let email of emailMatches) {
    //     str = str.replace(email, `<a href="mailto: ${email}">${email}</a>`);
    //   }
    // }

    return str;
  }
  /*-----------------------------------------------------------*/
}
