<app-footer-main footerClass="iq-ptb-20" id="contact-us" classes="{{classes}}">
  <div footerContent class="{{classes}}">
     <div class="row justify-content: space-between">
        <div class="col-lg-3 col-md-6 col-sm-6 iq-mtb-20">
           <app-footer-logo 
              className="{{classes}}"
              sectionImage="{{sectionImage}}"
              sectionColor="white"
              sectionText="Corporación Fibex Telecom, C.A. empresa líder de telecomunicaciones en Venezuela y proveedor de servicios de internet de alta velocidad para Hogar, PYME'S, Empresas, ISP."
              ></app-footer-logo>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6 iq-mtb-20">
           <app-footer-address [addressContent]="footerText" className="{{classes}}"></app-footer-address>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 iq-mtb-20">
         <app-footer-address addressTitle="Horario laboral" addressClass="addresss-info" [addressContent]="footerTiming" className="{{classes}}"></app-footer-address>
        </div>

        <div class="col-lg-2 col-md-6 col-sm-6 iq-mtb-20">
         <div>
            <h4 style="font-size:24px;margin-bottom: 10px;font-weight: 700" className="{{classes}}">Menú</h4>
            <a href="/Empresa" style="text-decoration:none;" className="{{classes}}">Empresa</a><br>
            <a href="/Hogar" style="text-decoration:none;" className="{{classes}}">Hogar</a><br>
            <a href="https://reporte.fibextelecom.net/" target="_blank" style="text-decoration:none;" className="{{classes}}">Reportar Avería</a><br>
            <a href="https://fast.com/es/" target="_blank" style="text-decoration:none;" className="{{classes}}">Test de Velocidad</a>
            <br>
             <a href="/Zona-cobertura" target="_blank" style="text-decoration:none;" className="{{classes}}">Zonas de Cobertura</a>
         </div>
         <!-- <app-footer-links title="Menú" [content]="footerHome">
            </app-footer-links> -->
        </div>
     </div>

     <hr>
     <div class="row iq-mt-20 align-items-center">
        <div class="col-lg-8 col-md-7 col-sm-12 text-left iq-font-dark">
           <div class="" [innerHTML]="footerTextBottom">© {{fullyear}} FIBEX TELECOM.<b>Todos los derechos Reservados</b>.</div>
        </div>
        <div class="col-md-5 col-lg-4 col-sm-12 align-self-center">
           <ul class="info-share text-center text-sm-right">
              <li *ngFor="let option of socialNavItems">
                 <a class="pointer" (click)="NewWindows(option.href)" [innerHTML]="option.icon"></a>
              </li>
           </ul>
        </div>
     </div>
  </div>
</app-footer-main>
