import { Component, OnInit } from '@angular/core';
import { ConsultasMapsService } from 'core/services/consultas-map.service';
import { ConsultasService } from '../../../core/services/consultas.service';
import { Router } from '@angular/router';
import { Sucursales } from 'src/app/core/interfaces/sucursales';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  dropdownOffice: boolean = false;
  MapaUrl: string;
  ubicaciones: Sucursales[] = [];
  statesToShow: Sucursales[] = [];
  offices: Sucursales[] = [];
  officesGreaterThan1: boolean = false;

  constructor(
    private router: Router,
    public _globalS: ConsultasMapsService,
    private consultasServ: ConsultasService
  ) {
    
  }

  ngOnInit() {
    this.getSucursalesData();
  }

  getSucursalesData() {
    this.consultasServ.getSucursales().then((res) => {
      this.ubicaciones = res;
      this.MapaUrl = this.ubicaciones[0].urlWeb;
      let secondIndex = 0;

      for(let i = 0; i < res.length; i++) {
        if(this.statesToShow.length > 0) {
          if(this.statesToShow[ secondIndex ].id !== res[i].id) {
            this.statesToShow.push(res[i]);
            secondIndex++
          }
        } else {
          this.statesToShow.push(res[i]);
        }
      }
    });
  }

  NewUrl( id: number ) {
    let location = this.ubicaciones.filter((sede) => sede.id == id)
    
    if( location.length > 1 ) {
      this.offices = location;
      this.officesGreaterThan1 = true;
    } else {
      this.officesGreaterThan1 = false;
      this._globalS.DatosMapas.emit(location);
      this.MapaUrl = location[0].urlWeb;
    }
  }

  setMapsValues( add: string ) {
    let location = this.ubicaciones.filter( sede => sede.address[0] === add );
    this._globalS.DatosMapas.emit(location);
    this.MapaUrl = location[0].urlWeb;
  }

  contactNavigate() {
    this.router.navigateByUrl('/contacto')
  }
}
