import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthUserFibexService {

  constructor(
    private http: HttpClient
    ) { 

    }

    authFibexUser(dni: any){
      return this.http.get<any>(`https://api.thomas-talk.me/SaldoCe/${dni}/584120202020@c.us`);
    }

    authFibexUserCableHogar(dni: any){
      return this.http.get<any>(`https://api.thomas-talk.me/SaldoCe/${dni}/584121311690@c.us`);
    }
}
