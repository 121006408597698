import { Component, Input, OnInit } from '@angular/core';
import { ProductInterface } from '../../../../core/interfaces/promo';

@Component({
    selector: 'app-card-style10',
    templateUrl: './card-style10.component.html',
    styleUrls: ['./card-style10.component.css']
})
export class CardStyle10Component implements OnInit {
    @Input() item: ProductInterface;
    @Input() showSizeLg: boolean = false;
    currentCategory: string = '';
    categories: { name: string, class: string }[] = [
        { name: 'Gastronomía', class: 'gastronomy' },
        { name: 'Tecnología', class: 'technology' },
        { name: 'Salud y Bienestar', class: 'health' },
        { name: 'Educación y Deportes', class: 'sport' },
        { name: 'Turismo', class: 'turism' },
        { name: 'Retail', class: 'retail' },
        { name: 'Entretenimiento', class: 'entertainment' },
    ];

    constructor() {
    }

    ngOnInit(): void {
        const index = this.categories.findIndex(eCategory => eCategory.name === this.item.Categoria)
        this.currentCategory = this.categories[index]?.class
    }

    NewWindows(url: any, ev: any) {
        ev.preventDefault()
        window.open(url)
    }
}
