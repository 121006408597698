import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, environment.key_enc, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
  }

  decrypt(value: any): string {
    return CryptoJS.AES.decrypt(value, environment.key_enc, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
  }

  Descryp(Datos: any) {
    let Data: any = CryptoJS.AES.decrypt(Datos.message, environment.key_enc, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    if (typeof Data == "string") { return JSON.parse(Data) } else { return Data }
  }

  btoa(str:string){
    return btoa(str)
  }
}
