import { Component, HostListener, Input, OnInit } from '@angular/core';
import $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-style1',
  templateUrl: './header-style1.component.html',
  styleUrls: ['./header-style1.component.css']
})
export class HeaderStyle1Component implements OnInit {

  @Input() logoImg: string;

  @Input() navItemList: any[];

  @Input() className: string;

  @Input() styledLogo = true;

  @Input() MenuSelect: string;

  @Input() hideClubFibexButton: boolean = false;

  @Input() childClass?: string;

  @Input() btnClass?: string;

  auxClassName: string = ''

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //     if (document.documentElement.scrollTop > 100) {
  //       this.className = this.auxClassName
  //     } else {
  //       this.className = ''
  //     }    
  // }

  constructor(
    private route: Router
  ) {

  }

  ngOnInit() {
    this.auxClassName = this.className
  }

  jumpTo(href) {
    if (href) {
      $('html, body').stop().animate({
        scrollTop: $(href).offset().top
      }, 100);
    }
  }

  goUpScreen(){
    $('body,html').animate({
      scrollTop: 0
    }, 100);
  }

  NewWindows(url: any, ev: any) {
    ev.preventDefault()
    window.open(url)
  }

  goToHome() {
    this.route.navigate(['/', 'home']);
  }

  goToClubFIbex(ev: any) {
    ev.preventDefault()
    this.route.navigate(['/club-fibex'])
  }

  goTo(){
    window.open('http://instagram.com/club.fibextelecom', '_blank')
  }
}
