import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-style8',
  templateUrl: './card-style8.component.html',
  styleUrls: ['./card-style8.component.css']
})
export class CardStyle8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  List: any[] = [
    { title: 'Ingresar a tienda de canales', image: './assets/images/Fibex/roku1.png', description: 'Diríjase a la tienda de canales de roku, busque «Fibex Play».' },
    { title: 'Buscar canal de Fibex Play', image: './assets/images/Fibex/roku2.png', description: 'Al ingresar a la tienda, ingrese a la categoría «Peliculas y TV» y busque el canal de Fibex Play. También puede buscar el canal a través del buscado de Roku.' },
    { title: 'Agregar canal de Fibex Play', image: './assets/images/Fibex/roku3.png', description: 'Seleccione el canal y presione agregar. Al finalizar la descarga tenemos que seleccionar la opción de «OK» para confirmar.' },
    { title: 'Abrir Fibex Play', image: './assets/images/Fibex/roku4.png', description: 'Para finalizar vuelva al menú de inicio, aparecerá entre sus canales el cana de Fibex Play recientemente agregado.' },
    /* { title: 'Tener Fibex Play en TV Box', image: './assets/images/Fibex/step5.png', description: 'Finalmente, buscamos la aplicación en nuestro menú de apps del dispositivo para empezar a disfrutar del contenido.' }, */
  ];
}
