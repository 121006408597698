import { AfterContentInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import Swiper from 'swiper';

@Component({
    selector: 'app-wallpaper',
    templateUrl: './wallpaper.component.html',
    styleUrls: ['./wallpaper.component.css']
})
export class WallpaperComponent implements OnInit, AfterContentInit {
    @Input() wallpapers: any[] = [];
    @Output() redirection = new EventEmitter<any>()

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.updateDimensions();
    }

    barInterval: NodeJS.Timeout
    isMobile: boolean = false;

    public swiper: Swiper

    constructor() { }

    ngOnInit(): void {
        this.updateDimensions();
    }

    ngAfterContentInit(): void {
        this.swiper = (document.getElementById('swiper') as any).swiper
        this.swiper.init()
        this.moveProgressBar()
        this.swiper.on('slideChange', this.moveProgressBar)
    }

    moveProgressBar = (): void => {
        this.swiper.slideReset()
        document.querySelectorAll('.bar').forEach(bar => bar.setAttribute('style', 'width: 0%'))//Set all other bars to width 0
        if (this.barInterval) clearTimeout(this.barInterval)//Stop the interval of all other bars
        var barElem = document.getElementById(`bar-${this.swiper.activeIndex}`);//get the active bar
        var width = 1;
        if (barElem) {// if the active bar element was found
            this.barInterval = setInterval(function () {
                if (width >= 100) clearInterval(this.barInterval);
                else width++;
                barElem.style.width = width + "%";
            }, 48);
        }
    }

    goToSlide = (index: number): void => {
        const swiper: any = (document.getElementById('swiper') as any).swiper
        swiper.slideTo(index)
        console.log(this.wallpapers);
    }


    updateDimensions(){
        const width = window.screen.availWidth
        width <= 540 ? this.isMobile = true : this.isMobile = false
    }

    emitRedirection(){
        this.redirection.emit();
    }
}
