<div class="iq-pricing mb-4 mb-lg-0 text-center wow fadeInUp {{ className }}">
  <div>
      <div>
          <app-parallax-hero-style1 bgImage="./assets/images/Fibex/Tarifas.png" classNames="price-title iq-parallax">
              <ng-content select="[cardHeader]"></ng-content>
            </app-parallax-hero-style1>
          <ng-content select="[cardList]"></ng-content>
          <div class="price-footer">
              <ng-content select="[cardFooter]"></ng-content>
          </div>
          <!-- <span>
              <img (click)="flip()" src="./assets/images/Fibex/arr.png" alt="flip" width="16%">
            </span> -->
      </div>
  </div>
</div>
