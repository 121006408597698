import { Component, OnInit, Input } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.css']
})
export class ChannelListComponent implements OnInit {
  @Input() data: any;

  private URLCertificadaIMG = new Map<string, any>();
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void { 
    console.log('this.data', this.data);
  }

  out() {
    this.activeModal.dismiss();
  }

  isURLCertificada(url: string) {
    if (this.URLCertificadaIMG.has(url)) {
      return url
    } else {
      return 'blank.png'
    }
  }
}
