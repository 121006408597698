<!--
 <section style="padding: 1% 4%; text-align: center;background: linear-gradient(to right,#fa787f,#ffd085);">
  <h3 class="textcontent">INSTALACIÓN DE FIBEX PLAY PARA ROKU TV</h3><br>
  <h4 class="textcontent">Las instrucciones a continuación aplica para todos los dispositivos existentes: Roku Express, Roku Express 4K, Roku Streaming Stick 4K y Roku Streambar.</h4><br>
  <div>
    <div class="card" style="background: linear-gradient(to right,#fa787f,#ffd085);">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 1</h3>
        <img src="./assets/images/Fibex/numero-uno.png" alt="" style="width: 16%;">
        <h4>Ingresar a tienda de canales</h4>
        <p>Diríjase a la tienda de canales de roku, busque «Fibex Play».</p>
      </div>
      <img src="./assets/images/Fibex/roku1.png" alt="" style="width: 100%;">
    </div>
    <div class="card">
      <img src="./assets/images/Fibex/roku2.png" alt="" style="width: 100%;">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 2</h3>
        <img src="./assets/images/Fibex/numero-2.png" alt="" style="width: 16%;">
        <h4>Buscar canal de Fibex Play</h4>
        <p>Al ingresar a la tienda, ingrese a la categoría «Peliculas y TV» y busque el canal de Fibex Play. También puede buscar el canal a través del buscado de Roku.</p>
      </div>
    </div>
    <div class="card">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 3</h3>
        <img src="./assets/images/Fibex/numero-3.png" alt="" style="width: 16%;">
        <h4>Agregar canal de Fibex Play</h4>
        <p>Seleccione el canal y presione agregar. Al finalizar la descarga tenemos que seleccionar la opción de «OK» para confirmar.</p>
      </div>
      <img src="./assets/images/Fibex/roku3.png" alt="" style="width: 100%;">
    </div>
    <div class="card">
      <img src="./assets/images/Fibex/roku4.png" alt="" style="width: 100%;">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 4</h3>
        <img src="./assets/images/Fibex/numero-cuatro.png" alt="" style="width: 16%;">
        <h4>Abrir Fibex Play</h4>
        <p>Para finalizar vuelva al menú de inicio, aparecerá entre sus canales el cana de Fibex Play recientemente agregado.</p>
      </div>
    </div>

    <br><br>
    </div>
</section> -->

<section style="padding: 1% 4%; text-align: center;background: linear-gradient(146deg, #ffffff,#ffffff, #7cc8ed);">
  <h3 class="textcontent">INSTALACIÓN DE FIBEXPLAY PARA ROKU TV</h3><br>
  <h4 class="textcontent">Las instrucciones a continuación aplica para todos los dispositivos existentes: Roku Express, Roku Express 4K, Roku Streaming Stick 4K y Roku Streambar</h4><br>
  <div>
    <div class="card" style="background: linear-gradient(to right,#fa787f,#ffd085);">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 1</h3>
        <img src="./assets/images/Fibex/numero-uno.png" alt="" style="width: 16%;">
        <h4>Ingresar a tienda de canales</h4>
        <p>Diríjase a la tienda de canales de roku, busque «Fibex Play»</p>
      </div>
      <img src="./assets/images/Fibex/roku1.png" alt="" style="width: 100%;">
    </div>
    <div class="card">
      <img src="./assets/images/Fibex/roku2.png" alt="" style="width: 100%;">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 2</h3>
        <img src="./assets/images/Fibex/numero-2.png" alt="" style="width: 16%;">
        <h4>Buscar canal de Fibex Play</h4>
        <p>Al ingresar a la tienda, ingrese a la categoría «Peliculas y TV» y busque el canal de Fibex Play. También puede buscar el canal a través del buscado de Roku</p>
      </div>
    </div>
    <div class="card">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 3</h3>
        <img src="./assets/images/Fibex/numero-3.png" alt="" style="width: 16%;">
        <h4>Agregar canal de Fibex Play</h4>
        <p>Seleccione el canal y presione agregar, al finalizar la descarga tenemos que seleccionar la opción de «OK» para confirmar</p>
      </div>
      <img src="./assets/images/Fibex/roku3.png" alt="" style="width: 100%;">
    </div>
    <div class="card">
      <img src="./assets/images/Fibex/roku4.png" alt="" style="width: 100%;">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 4</h3>
        <img src="./assets/images/Fibex/numero-cuatro.png" alt="" style="width: 16%;">
        <h4>Abrir Fibex Play</h4>
        <p>Para finalizar vuelva al menú de inicio, aparecerá entre sus canales el cana de Fibex Play recientemente agregado</p>
      </div>
    </div>
    <br><br>
    </div>
</section>

<!-- <section id="iq-about" class="overview-block-ptb" style="background: linear-gradient(146deg, #ffffff,#ffffff, #7cc8ed);border:0px;">
  <div class="container">
      <div class="row pt-4 pr-3 pl-3 fancy-service-list wow fadeInUp text-center rescard">
        <h3 class="textcontent">INSTALACIÓN DE FIBEX PLAY PARA ROKU TV</h3><br>
  <h4 class="textcontent">Las instrucciones a continuación aplica para todos los dispositivos existentes: Roku Express, Roku Express 4K, Roku Streaming Stick 4K y Roku Streambar.</h4><br>
        <div *ngFor="let list of List" class="col-lg-4 col-md-6 col-sm-12">
          <div class="fancy-service-detail">
              <div class="iq-img">
                  <img class="fancy-list-img" src="{{ list.image }}" alt="fancy-list" style="width: 130% !important;"><i class="ion ion-checkmark"></i>
              </div>
              <h5><strong>{{ list.title }}</strong></h5>
              <p class="mb-0">{{ list.description }}</p>
          </div>
      </div><br>

      </div>
  </div>
</section> -->
