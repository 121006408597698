<!-- Header -->
<header id="main-header" [ngClass]="className">
  <ng-content select="[topBar]"></ng-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="" onclick="javascript:goToHome()" style="z-index: 100;">
            <img width="100%" class="img-fluid" [id]="styledLogo ? 'logo_img' : ''" src="{{ logoImg }}" alt="#">
          </a>          

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="ion-navicon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto w-100 justify-content-end menu" [ngClass]="!hideClubFibexButton ? 'ul_margin_left': ''">

              <li class="nav-item menu-item uncollapsedBtns" *ngIf="hideClubFibexButton">

                <div class="CFsocialMediaContainer">
                  <img src="../../../../../../assets/images/Landing_Club_Fibex/insta.png" alt="" class="socialMediaIcon instagramIcon" (click)="goTo()" style="margin-left: 2%;">
  
                  <button style="background: transparent; border: 0px;">
                    <app-chatbot></app-chatbot>
                  </button>

                </div>
              </li>

              <li class="nav-item menu-item uncollapsedBtns">
                <a class="nav-link {{childClass}}"
                  (click)="NewWindows('https://oficinamovil.fibextelecom.net/', $event)" href="#">
                  Oficina Móvil
                </a>
              </li>
              
              <li class="nav-item menu-item uncollapsedBtns" *ngIf="!hideClubFibexButton">
                <a class="nav-link {{childClass}}"
                  (click)="goToClubFIbex($event)" href="#">
                  Club Fibex
                </a>
              </li>

              <li class="nav-item menu-item" *ngFor="let option of navItemList">
                <a class="nav-link {{ option._is_active !== undefined && option._is_active ? 'active' : '' }} {{childClass}}"
                  (click)="jumpTo(option.href); false" href="{{ option.href}}">
                  {{ option.title }}
                  <i *ngIf="option.children" class="fa fa-angle-down toggledrop" aria-hidden="true"></i>
                </a>
                <ul class="sub-menu {{childClass}}" *ngIf="option.children">
                  <div *ngFor="let child of option.child">
                    <li id="menu-item-506" *ngIf="child.title != MenuSelect">
                      <a (click)="goUpScreen()" [routerLink]="child.href" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact: true}">{{ child.title }}</a>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
            <div class="header_btns collapseBtns">

              <div class="header_btns">
                <span class="{{btnClass}}">
                  <button (click)="NewWindows('https://oficinamovil.fibextelecom.net/', $event)" class="button bt-black pull-right">
                    Oficina Móvil
                  </button>
                </span>
                <span *ngIf="!hideClubFibexButton" class="{{btnClass}} shakeIt">
                  <button (click)="goToClubFIbex($event)" class="button bt-black pull-right cfBtn">
                    Club Fibex
                  </button>
                </span>
                
                <img *ngIf="hideClubFibexButton" width="6%" src="../../../../../../assets/images/Landing_Club_Fibex/insta.png" alt="" class="socialMediaIcon" (click)="goTo()" style="margin-left: 2%;">
              </div>
              
              <button *ngIf="hideClubFibexButton" style="background: transparent; border: 0px;">
                <app-chatbot></app-chatbot>
              </button>

              <ng-content select="[navContent]"></ng-content>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Header End -->
