<div *ngIf="!showChatbox; else elseBlock" class="open-chat-button" (click)="chatInit()">
  <img src="../../../../assets/images/Landing_Club_Fibex/faqcf.png" alt="" class="pulseIt faqcf">
</div>
<ng-template #elseBlock>
  <div id="chatbox">
    <div class="chatbox-title">
      <h5 (click)="toggleMinimize()"><a href="javascript:void(0)">Asistente Virtual</a></h5>
      <button class="tray" (click)="toggleMinimize()">
        <span></span>
      </button>
      <button class="close" (click)="destroyChat()">
        <span>
          <svg viewBox="0 0 12 12" width="12px" height="12px">
            <line stroke="#FFFFFF" x1="11.75" y1="0.25" x2="0.25" y2="11.75"></line>
            <line stroke="#FFFFFF" x1="11.75" y1="11.75" x2="0.25" y2="0.25"></line>
          </svg>
        </span>
      </button>
    </div>

    <div *ngIf="!isMinimized" @chatbox-minimize (@chatbox-minimize.start)="afterChatboxIsOpen()">

      <div class="faq-container">
        <span style="--background: white !important;" class="faq-btn" shape="round" (click)="sendMessage('1','¿Qué es Club Fibex?')"> <i class="fas fa-circle-question iconic"></i> ¿Qué es Club Fibex?</span>
        <span style="--background: white !important;" class="faq-btn" shape="round" (click)="sendMessage('2','¿Quién puede pertenecer al Club Fibex?')"><i class="fas fa-circle-question iconic"></i> ¿Quién puede pertenecer al Club Fibex?</span>
        <span style="--background: white !important;" class="faq-btn" shape="round" (click)="sendMessage('3','¿Qué encontraré en Club Fibex?')"><i class="fas fa-circle-question iconic"></i> ¿Qué encontraré en Club Fibex?</span>
        <span style="--background: white !important;" class="faq-btn" shape="round" (click)="sendMessage('4','¿Quieres ser parte de nuestras marcas aliadas?')"><i class="fas fa-circle-question iconic"></i> ¿Quieres ser parte de nuestras marcas aliadas?</span>
        <span style="--background: white !important;" class="faq-btn" shape="round" (click)="sendMessage('5','¿Cómo disfrutar de los beneficios del Club Fibex?')"><i class="fas fa-circle-question iconic"></i> ¿Cómo disfrutar de los beneficios del Club Fibex?</span>
        <!-- <input class="message-input" type="text" maxlength="256" [(ngModel)]="messageInput" (keydown.enter)="sendMessage()" placeholder="Escriba un mensaje..."> -->
      </div>

      <div id="chatbox-body" #chatboxBody>
        <div class="empty-space"></div>

        <!-- message "component" -->
        <div *ngFor="let message of messages" [ngClass]="['message-container', message.from == 'bot' ? 'message-container--left' : 'message-container--right']">
          <img class="profile-icon" [src]="message.from == 'bot' ? '../../../../assets/images/Landing_Club_Fibex/fibexito.png' : '../../../../assets/images/Landing_Club_Fibex/chatbot-client-icon.png'" alt="profile icon">
          <div class="message-box-container">
            <div class="message-box">
              <div class="message-content">
                <span style="white-space: pre-line;" [ngClass]="message.error && 'bot-error-message'" [innerHTML]="message.content"></span>
                
                <!-- images to be displayed that come from the bot -->
                <div *ngIf="message.form != undefined" [formGroup]="message.form.formGroup">
                  <!-- select control -->
                  <select *ngIf="message.form.formControls.select != undefined" formControlName="select" class="form-control form-select">
                    <option value="" disabled selected hidden>Selecciona una opción</option>
                    <option *ngFor="let dropdownOption of message.form.formControls.select" [value]="dropdownOption.value">{{ dropdownOption.label }}</option>
                  </select>

                  <!-- other controls would be defined down here -->
                  <!-- ADD YOUR NEW CONTROL HERE -->
                </div>

                <!-- images to be displayed that come from the bot -->
                <div *ngIf="message.images != undefined">
                  <div class="message-image-container" *ngFor="let imageUrl of message.images">
                    <img class="message-image img-fluid" [src]="imageUrl" [alt]="imageUrl">
                  </div>
                </div>

                <!-- button options when the bots prompts the user to take an action -->
                <div class="message-button-group" *ngIf="message.buttons != undefined">
                  <button class="message-button btn btn-primary btn-sm" *ngFor="let option of message.buttons" (click)="selectOption(option)">{{ option.value }}</button>
                </div>

              </div>
              <!-- display question regarding customer satisfaction with two options (thumbs up or thumbs down) -->
              <div *ngIf="message.askForRating" class="customer-rating">
                ¿Fue de ayuda nuestra respuesta?
                <span class="rating-icon-group">
                  <a href="#" style="margin-right: 10px; margin-left: 10px;"><i class="ion-thumbsup botton" (click)="sendCustomerSatisfaction(true)"></i></a>&nbsp;<a href="#"><i class="ion-thumbsdown" (click)="sendCustomerSatisfaction(false)"></i></a>
                </span>
              </div>
              
            </div>
          </div>
          <span class="time-sent">{{ message.timeSent }}</span>
        </div>

        <!-- "Bot is typing..." animation gets loaded when user is waiting for the bot's response -->
        <div *ngIf="isWaitingForMessage" class="message-container message-container--left">
          <img class="profile-icon" style="top: 15px;" src="../../../../assets/images/Landing_Club_Fibex/fibexito.png" alt="Picture">
          <div class="chat-bubble">
            <div class="typing">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </div>

      </div>

      
    </div>
  </div>
</ng-template>