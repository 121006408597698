import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { Title, Meta } from '@angular/platform-browser';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  // title = 'sofbox-angular';
  public title: string = 'Fibex Telecom';

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'Internet, Funciona, Fibex, Telecom,' },
      { name: 'description', content: '¡El Internet que SÍ Funciona! Te conecta con el mundo. Experimenta la velocidad y estabilidad de nuestro servicio hasta tu hogar.' },
      { name: 'robots', content: 'index' }
    ]);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
