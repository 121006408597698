
<!-- SEGUNDA SECCION -->
<!-- <section style="padding: 1% 4%; text-align: center;background: linear-gradient(146deg, #ffffff,#ffffff, #7cc8ed);">
  <h3 class="textcontent">INSTALACIÓN DE FIBEX PLAY PARA TV BOX</h3><br>
  <h4 class="textcontent">Las instrucciones a continuación aplica para todos los dispositivos existentes: Q+ Smart Box, T95 MAX, Khadas VIM3, SUNNZO X96 mini, Xiaomi Mi TV Box S. QP Love Q6 TV Box, Beelink GT King, T95 S1 Android TV Box, Nvidia Shield TV Pro</h4><br>
  <div>
    <div class="card" style="background: linear-gradient(to right,#fa787f,#ffd085);">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 1</h3>
        <img src="./assets/images/Fibex/numero-uno.png" alt="" style="width: 16%;">
        <h4>Descargar e instalar Downloader</h4>
        <p>Si aun no tiene instalada la App Downloader en su TV Box, diríjase a la Play Store, busque «Downloader», proceda a instalar y abrir</p>
      </div>
      <img src="./assets/images/Fibex/step1.png" alt="" style="width: 100%;">
    </div>
    <div class="card">
      <img src="./assets/images/Fibex/step2.png" alt="" style="width: 100%;">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 2</h3>
        <img src="./assets/images/Fibex/numero-2.png" alt="" style="width: 16%;">
        <h4>Copiar link de descarga de Fibex Play</h4>
        <p>En «Downloader» escribiremos el link de descarga de Fibex Play tal como se señala en la imagen y hacemos clic en «GO» para iniciar la descarga</p>
      </div>
    </div>
    <div class="card">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 3</h3>
        <img src="./assets/images/Fibex/numero-3.png" alt="" style="width: 16%;">
        <h4>Instalar App Fibex Play</h4>
        <p>Al finalizar la descarga tenemos que seleccionar la opción de «INSTALL» y seguir los pasos para que se realice la instalación</p>
      </div>
      <img src="./assets/images/Fibex/step3.png" alt="" style="width: 100%;">
    </div>
    <div class="card">
      <img src="./assets/images/Fibex/step4.png" alt="" style="width: 100%;">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 4</h3>
        <img src="./assets/images/Fibex/numero-cuatro.png" alt="" style="width: 16%;">
        <h4>Instalación final de la App en TV Box</h4>
        <p>Se confirma la instalación de la aplicación y esperamos unos segundos para que finalice. Hasta aquí se realiza el proceso para instalar Fibex Play</p>
      </div>
    </div>
    <div class="card">
      <div style="text-align: center; background-color: #7cc8ed;padding: 0% 2%;">
        <h3>PASO 5</h3>
        <img src="./assets/images/Fibex/numero-5.png" alt="" style="width: 16%;">
        <h4>Tener Fibex Play en TV Box</h4>
        <p>Finalmente, buscamos la aplicación en nuestro menú de apps del dispositivo para empezar a disfrutar del contenido</p>
      </div>
      <img src="./assets/images/Fibex/step5.png" alt="" style="width: 100%;">
    </div>
    <br><br>
    </div>
    <h3 class="textcontent">NO PUEDO INSTALAR FIBEX PLAY</h3><br>
    <h4 class="textcontent">Si no puede descargar la aplicación con «Downloader», le recomendamos eliminar «Downloader» y volver a instalarla para intentar realizar la instalación una vez más de FibexPlay</h4>
</section> -->


<!-- SECCION ORIGINAL -->
<!-- <section id="iq-about" class="overview-block-ptb" style="background: linear-gradient(146deg, #ffffff,#ffffff, #7cc8ed);border:0px;">
  <div class="container">
      <div class="row pt-4 pr-3 pl-3 fancy-service-list wow fadeInUp text-center rescard">
        <h3 class="textcontent">INSTALACIÓN DE FIBEX PLAY PARA TV BOX</h3><br>
  <h4 class="textcontent">Las instrucciones a continuación aplica para todos los dispositivos existentes: Q+ Smart Box, T95 MAX, Khadas VIM3, SUNNZO X96 mini, Xiaomi Mi TV Box S. QP Love Q6 TV Box, Beelink GT King, T95 S1 Android TV Box, Nvidia Shield TV Pro.</h4><br>
        <div *ngFor="let list of List" class="col-lg-4 col-md-6 col-sm-12">
          <div class="fancy-service-detail">
              <div class="iq-img">
                  <img class="fancy-list-img" src="{{ list.image }}" alt="fancy-list" style="width: 130% !important;"><i class="ion ion-checkmark"></i>
              </div>
              <h5><strong>{{ list.title }}</strong></h5>
              <p class="mb-0">{{ list.description }}</p>
          </div>
      </div><br>
              <div class="col-sm-12" [ngClass]="">
                 <div class="col-sm-12 heading-title">
                  <h3 class="textcontent">NO PUEDO INSTALAR FIBEX PLAY</h3><br>
                  <h4 class="textcontent">Si no puede descargar la aplicación con «Downloader», le recomendamos eliminar «Downloader» y volver a instalarla para intentar realizar la instalación una vez más de Fibex Play.</h4>
                 </div>
              </div>

      </div>
  </div>
</section> -->

<section class="container section-fb">
  <div class="text-center">
    <h2><strong>{{data.title | uppercase}}</strong></h2>
    <p style="color:#000000;"><strong>{{data.subtitle | uppercase}}</strong></p>
  </div>
  <div class="card-fb">
    <div>
      <img src="{{data.step1.icon}}">
      <h4>{{data.step1.title}}</h4>
      <p>{{data.step1.subtitle}}</p>
    </div>
    <div>
      <img src="{{data.step2.icon}}">
      <h4>{{data.step2.title}}</h4>
      <p>{{data.step2.subtitle}}</p>
    </div>
    <div>
      <img src="{{data.step3.icon}}">
      <h4>{{data.step3.title}}</h4>
      <p>{{data.step3.subtitle}}</p>
    </div>
  </div><br><br>
  <div class="card-fb-footer bga" *ngIf="data.footer !== ''">
    <p>{{data.footer}}</p>
  </div>
</section>
