import { Injectable } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import { SpinnerViewComponent } from '../components/spinner-view/spinner-view.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private spinerLoading: any;

  private currentBannerSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly currentBanner: Observable<any> = this.currentBannerSubject.asObservable();

  constructor(
    private spinnerService: NgxSpinnerService
    ) {
    this.spinerLoading = new SpinnerViewComponent(spinnerService);
  }

  spinnerServiceHide() {
    setTimeout(() => {
      this.spinnerService.hide();
      this.spinnerKeypressController(true)
    }, 1500);
  }

  spinnerServiceImmediateHide() {
    this.spinnerKeypressController(true)
    this.spinnerService.hide();
  }

  spinnerServiceOpenAndBlock(loadingMessage: string) {
    this.spinerLoading.spinnerKeyPressController(loadingMessage);
  }

  spinnerKeypressController(keyValue: boolean) {
    onkeydown = e => {
      let tecla = e.which || e.keyCode;
      if (tecla == 9 || tecla == 13) {
        return keyValue
      }
    }
  }

  setBanners(banners: string[]){
    this.currentBannerSubject.next(banners);
  }
}