import { Component, Input, Output, EventEmitter, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScreenSize()
  }
  @Output() closeEventEmit: EventEmitter<void> = new EventEmitter<void>()
  @Input() sourceMedia: string = '';
  @Input() showPopUp: boolean = false;
  @Input() isVideo: boolean = false;
  @Input() isPhoto: boolean = false;
  public isSmallScreen: boolean = false;

  constructor() { }

  ngOnInit(): void {
    try {
      if (this.sourceMedia?.endsWith('.mp4')) this.isVideo = true;
      else if (this.sourceMedia?.endsWith('.png') || this.sourceMedia?.endsWith('.jpg') || this.sourceMedia?.endsWith('.web')) this.isPhoto = true;
    } catch (error) {
      console.error(error)
    }
  }

  /**
 * function to define the size of images according to the size of the device
 */
  public setScreenSize = (): void => {
    this.isSmallScreen = (window.screen.availWidth <= 540)
  }

  /**
   * To emit the button event was pressed
   */
  public closeEmit = (): void => {
    this.closeEventEmit.emit();
  }

  public goTo = (): void => {
    //! Play store
    // window.open('https://play.google.com/store/apps/details?id=com.jermsoft.fibexapp.v2&hl=es_VE&gl=US', '_system')

    //! Oficina Movil App (web)
    window.open('https://oficinamovil.fibextelecom.net/', '_system')
  }

  public exitOnClick(event: CustomEvent<HTMLElement>) {
    let target = (event.target as HTMLElement)
    if (target.id !== 'video-player' && target.id !== 'image-popup') this.closeEmit()
  }

}
