import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { HttpClientModule } from '@angular/common/http';
import { ContactFormService } from './shared/components/contact-form/contact-form.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthUserFibexService } from './core/services/auth-user-fibex.service';
import { OttService } from './core/services/ott.service';
import { SharedModule } from './shared/shared.module';

// import { NgxSpinnerModule } from 'ngx-spinner';
// import { SpinnerViewComponent } from './core/components/spinner-view/spinner-view.component';

@NgModule({
  declarations: [
    AppComponent,
    // SpinnerViewComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    // NgxSpinnerModule,
    AppRoutingModule,
    SharedModule

  ],
  providers: [
    AuthUserFibexService,
    OttService,
    ContactFormService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
