<section>
  <div style="position: relative;">
    <iframe [src]="MapaUrl | url" width="100%" height="800" frameborder="0" style="border:0; z-index: -1;"
      allowfullscreen></iframe>
    <span class="mapOptions">
      <select class="btn btn-light style" name="" id="" (change)="NewUrl($event.target.value)">
        <option selected disabled>Estado</option>
        <option [value]="state.id" *ngFor="let state of statesToShow">{{ state.ubicacion }}</option>
      </select>

      <select *ngIf="officesGreaterThan1"
              class="btn btn-light style" (change)="setMapsValues($event.target.value)">
        <option selected disabled>Oficinas</option>
        <option [value]="office.address" *ngFor="let office of offices">{{ office.address[0] }}</option>
      </select>
    </span>
    <button
      style="background: url('../../../../../assets/images/Fibex/imgFooter.png');position:absolute; top:18%;z-index: 1;left: 2%;font-weight: bold;"
      class="btn btn-primary" (click)="contactNavigate()">Contáctanos</button>
  </div>
</section>