import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-card-style11',
    templateUrl: './card-style11.component.html',
    styleUrls: ['./card-style11.component.css']
})
export class CardStyle11Component implements OnInit {
    @Input() image: string = '';
    @Input() name: string = '';

    constructor() { }

    ngOnInit(): void {
    }

}
