import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-spinner-view',
  templateUrl: './spinner-view.component.html',
  styleUrls: ['./spinner-view.component.css']
})
export class SpinnerViewComponent implements OnInit {

  constructor(
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  spinnerKeyPressController() {
    this.spinnerService.show();
  }

  spinnerUnlockAndHide() {
    setTimeout(() => {
      this.spinnerService.hide();
    }, 500);
  }

}
