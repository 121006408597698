import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { environment } from "src/environments/environment.prod";
import swal from 'sweetalert2';
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.css"],
})
export class ContactFormComponent implements OnInit {
  public regexEmail: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public regexNumb: RegExp = /^[0-9]*$/;
  public valueForm: boolean = false
  public contactForm: FormGroup | any;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private route: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }


  ngOnInit() {
    this.initForm()

  }

  initForm() {
    this.contactForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.regexEmail)]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(11), Validators.pattern(this.regexNumb), this.initWith]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
    })
  }

  get email() { return this.contactForm.get('email') }
  get firstname() { return this.contactForm.get('firstname') }
  get lastname() { return this.contactForm.get('lastname') }
  get phone() { return this.contactForm.get('phone') }
  get address() { return this.contactForm.get('address') }
  get city() { return this.contactForm.get('city') }

  async send() {

    const formData = [
      { property: "email", value: this.contactForm.get("email").value },
      { property: 'work_email', value: this.contactForm.get("email").value },
      { property: 'correo_electrnico', value: this.contactForm.get("email").value },
      { property: "firstname", value: this.contactForm.get("firstname").value },
      { property: "lastname", value: this.contactForm.get("lastname").value + " (FibexWeb)", },
      { property: "website", value: "https://fibextelecom.net/" },
      { property: "company", value: "Fibextelecom" },
      { property: "phone", value: this.contactForm.get("phone").value },
      { property: 'mobilephone', value: this.contactForm.get("phone").value },
      { property: "fuente_lead", value: "WEB SITE" },
      { property: 'hs_analytics_source', value: 'DIRECT_TRAFFIC' },
      //{ property: 'hs_analytics_last_url', value: this.document.location.href },
      //{ property: 'hs_analytics_source_data_1', value: 'WEB SITE' },
      { property: 'country', value: 'Venezuela' },
      { property: "message", value: `.- Nombre Completo: ${this.contactForm.get("firstname").value} ${this.contactForm.get("lastname").value || ""} .- Correo: ${this.contactForm.get("email").value} .- Ciudad: ${this.contactForm.get("city").value} .- Dirección: ${this.contactForm.get("address").value} .- Teléfono: ${this.contactForm.get("phone").value}`, },
      { property: "address", value: this.contactForm.get("address").value },
      { property: 'hs_timezone', value: 'america_slash_caracas' },
      { property: "city", value: this.contactForm.get("city").value },
      { property: 'state', value: 'MA' }
    ];

    if (formData[11].value >= 501) {
      formData[11].value = formData[7].value.substring(0, 500)
    }

    const query = formData.reduce((str, item) => str + `${item.property}=${item.value}&`, "").slice(0, -1)

    try {
      const request: any = await this.http.get(`${environment.hotspotApi}?${query}`).toPromise();


      if (request.error) {
        swal.fire({
          title: 'Correo electrónico ya registrado',
          text: 'Por favor, intente llenar nuevamente el formulario utilizando otro correo electrónico',
          timer: 3000,
        });
        this.contactForm.controls['email'].setValue('');
        this.contactForm.controls['firstname'].setValue('');
        this.contactForm.controls['lastname'].setValue('');
        this.contactForm.controls['phone'].setValue('');
        this.contactForm.controls['address'].setValue('');
        this.contactForm.controls['city'].setValue('');
        this.contactForm.updateValueAndValidity();
        this.contactForm.reset();
        return {
          error: true,
          data: null,
          message: request.errors[0].message,
        };
      } else {
        swal.fire({
          title: 'Formulario enviado <br> Gracias por su tiempo',
          timer: 3000
        });
        this.contactForm.controls['email'].setValue('');
        this.contactForm.controls['firstname'].setValue('');
        this.contactForm.controls['lastname'].setValue('');
        this.contactForm.controls['phone'].setValue('');
        this.contactForm.controls['address'].setValue('');
        this.contactForm.controls['city'].setValue('');
        this.contactForm.updateValueAndValidity();
        this.contactForm.reset();
        return {
          error: false,
          data: request,
        };
      }
    } catch (error) {
      return {
        error: true,
        data: null,
        message: error,
      };
    }
  }

  initWith(control: AbstractControl) {
    let numb = false
    const initWith = [
      "0416",
      "0426",
      "0412",
      "0414",
      "0424",
      "416",
      "426",
      "412",
      "414",
      "424",
      "58416",
      "58426",
      "58412",
      "58414",
      "58424",
    ];

    for (let i = 0; i < initWith.length; i++) {
      if (control.value.startsWith(initWith[i])) {
        numb = true;
      }
    }

    if (!numb) {
      return { invalidInit: true }
    }
    return null
  }
}
