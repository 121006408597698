<!-- <div class="custom_card">
  <div class="product_image bg_image" [style.backgroundImage]="'url('+ item.ImgURL +')'"></div>
  <span class="product_title font_poppins_light color_club_light_gray ellipsis">
    {{item.Nombre}}
  </span>
  <span class="product_price font_poppins_medium color_club_gray ellipsis">
    <strong>
      {{ item.Descuento }}$
    </strong>
    -
    <del>
      {{item.Precio}}$
    </del>
  </span>
</div> -->

<div class="single_pro_card_1" [ngClass]="{'size_lg': showSizeLg}" (click)="NewWindows('https://oficinamovil.fibextelecom.net/', $event)">

  <div class="bg_image pro_image product_image" [style.backgroundImage]="'url('+ (item.img ? item.img : '../../../../../assets/images/Globo.png') +')'">
    <div class="display_flex">
      <div class="btn_image"></div>
      <div class="display_flex flex_column">
        <div class="icon_container">
        </div>
        <div class="btn_image_2"></div>
      </div>
    </div>
  </div>

  <div class="divider {{currentCategory}}"></div>

  <div class="food_detail_cards">
    <span class="display_flex">
      <div class="company_logo bg_image"
        [style.backgroundImage]="'url('+ (item.company_logo ? item.company_logo : '../../../../../assets/images/Globo.png') +')'">
      </div>
      <div class="descuento_container">
        <span class="descuento">
          {{(item?.isDiscount ? item?.discount +'%' : '-'+item?.discount+'$')}}
        </span>
      </div>
    </span>
    <div class="name_lbl_container">
      <span class="name_lbl">
        <p>
          {{item.name | uppercase}}
        </p>
      </span>
    </div>
  </div>
</div>
