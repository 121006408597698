<div class="contact-bg">
    <h5 class="iq-tw-7 iq-mb-10 iq-font-{{ addressColor }} {{className}}">{{ addressTitle }}</h5>
    <ul [ngClass]="addressClass">
      <li *ngFor="let option of addressContent">
        <i *ngIf="option.icon" class="{{ option.iconClass }}"></i>
        <ul *ngIf="option.text != '04120202020'">
          <li>{{ option.text }}</li>
          <li>{{ option.textB }}</li>
          <li>{{ option.textC }}</li>
          <li><strong>{{ option.texta }}</strong></li>
          <li>{{ option.text1 }}</li>
          <li>{{ option.text2 }}</li>    
          <li>{{ option.text3 }}</li>
          <li>{{ option.text4 }}</li>
          <li> <strong>{{ option.text5 }}</strong></li>
          <li>{{ option.text6 }}</li>
        </ul>
        <a *ngIf="option.text == '04120202020'" href="https://api.whatsapp.com/send/?phone=584120202020&text&app_absent=0">02123108300</a>  
      </li>   
    </ul>  
  </div>
  