import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CryptoService } from './crypto.service';
import { Sucursales } from '../interfaces/sucursales';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class ConsultasService {

  AllPromociones: any = []

  constructor(
    private http: HttpClient,
    private enc: CryptoService
  ) {
    this.GetPromo()
  }

  GetPromo() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(`${environment.Apithomas}GetAllPromo`).subscribe((ResPromo: any) => {

          this.AllPromociones = ResPromo
          resolve(ResPromo)

        }, (err: any) => {
          reject(err)
          console.error(err)
        })

      } catch (error) {
        reject(error)
        console.error(error)
      }
    })
  }

  async getSucursales() {
    const headers = {
      directory: this.enc.encrypt('Consultas'),
      token: this.enc.encrypt(environment.apiKeySsl),
      method: this.enc.encrypt(environment.metodoSucursales),
      platform: this.enc.encrypt(environment.platform)
    }

    return axios.get<Sucursales[]>(`${environment.urlApiTls}`, { headers }).then((res) => {
      let data = this.enc.Descryp(res.data);
      return data;
    }).catch((err) => {
      console.error(err)
    })
  }

  getChannelsForInfo() {
    const headers: any = {
      directory: this.enc.encrypt('FibexPlay'),
      method: this.enc.encrypt("GetChanels"),
      token: this.enc.encrypt(environment.apiKeySsl),
      platform: this.enc.encrypt("FibexPLay"),
    }

    return axios.get(`${environment.urlApiTls}`, { headers }).then((res: any) => {
      let data = [this.enc.Descryp(res.data)];
      console.log(data)

      return data.map((res: any) => {

          const paquetes = [];
          const canalesPorCat = [];

          const DataPaquetes: any = res.Paquetes.filter((paquete: any) => !paquete.Paquete.includes("ADULTOS"));


          for (let i = 0; i < res.Canales.length; i++) {
            const Categorias = res.Categorias.find((categoria: any) => categoria.Id === res.Canales[i].Categoria);
            canalesPorCat.push({ ...res.Canales[i], nombreCategoria: Categorias.Categoria });

            if (i === res.Canales.length - 1) {
              if (DataPaquetes && DataPaquetes.length > 0) {
                for (let i = 0; i < DataPaquetes.length; i++) {
                  const canales = canalesPorCat.filter((canal: any) => canal.Paquete.includes(DataPaquetes[i].Id));
                  paquetes.push({
                    Paquete: DataPaquetes[i].Paquete,
                    Costo: DataPaquetes[i].Costo,
                    canales
                  });
                }
              }

              const Adultos: any = res.Categorias.find((FA: any) => FA.Categoria === "Adulto");
              const Canales: any = canalesPorCat.filter((canal: any, index: number) => canal.Categoria === Adultos.Id)
              res.Paquetes.forEach(element => {
                if (element.Costo > 0) {

                  if (element.Paquete.includes(res.Paquetes[res.Paquetes.length - 1].Paquete)) {
                    const str = element.Paquete.split('+');
                    const response = res.Paquetes.find(paquete => paquete.Paquete.includes(str[0].trim()));
                    paquetes.push({
                      Paquete: "FIBEXPLAY ADULTO",
                      Costo: element.Costo - response.Costo,
                      canales: Canales
                    });
                  }

                }
              });
            }
          }
          return paquetes
        })[0]
    }).catch((err) => {
      console.error(err)
    });




  }
}
