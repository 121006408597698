import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../../core/services/helper.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-banner-modal',
  templateUrl: './banner-modal.component.html',
  styleUrls: ['./banner-modal.component.css'],
  animations:[
    trigger('fade',[
      state('in',style({ opacity: 1})),
      state('out',style({opacity: 0})),
      transition('in => out', animate('3.6s ease-in')),
      transition('out => in', animate('2.8s ease-out'))
    ])
  ]
})

export class BannerModalComponent implements OnInit {
background: string = '';
timer;
state: string = 'in';
item:number = 0;
backgrounds: string[] = []

  constructor(private helper: HelperService, private activeModal: NgbActiveModal) {
    this.init()
   }

  ngOnInit(): void {

  }

  init(){
    this.helper.currentBanner.subscribe(response => {
      this.background = response[0]
      this.backgrounds = response
      // this.start(response)
    })
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.state = 'out';
    }, 0);
  }

  onEnd(event){
    if(this.state === 'out') {
      if(this.item === this.backgrounds.length - 1) {
        this.item = 0;
        this.background = this.backgrounds[this.item]

      }else{
        this.item++;
        this.background = this.backgrounds[this.item]

      }

    }

    this.state = 'in';
    if (event.toState === 'in') {
      setTimeout(() => {
        this.state = 'out';
      }, 2800);
    }
  }

  // start(backgrounds: string[]){
  //   let item:number = 0;
  //   this.background = backgrounds[item]

  //   this.timer = setInterval(() => {

  //     if(item === backgrounds.length - 1) {
  //       item = 0;
  //       this.background = backgrounds[item]

  //     }else{
  //       item++;
  //       this.background = backgrounds[item]

  //     }

  //   }, 4800);
  // }

  close(){
    this.activeModal.dismiss()
    clearInterval(this.timer)
  }
}
