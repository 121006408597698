import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerViewComponent } from './components/spinner-view/spinner-view.component';
import { NgxSpinnerModule } from 'ngx-spinner';



@NgModule({
  declarations: [
    SpinnerViewComponent,
  ],
  imports: [
    NgxSpinnerModule,
    CommonModule
  ],
  exports: [
    SpinnerViewComponent,
    NgxSpinnerModule,
  ],
  providers:[
  ]
})
export class CoreModule { }
