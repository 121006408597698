<div *ngIf="bgVideo; else bgImageTemplate" id="{{ ids }}" data-parallax="true" class="{{ classNames }}">
  <video autoplay loop [style.width]="'100%'" [style.height]="'100%'">
    <source [src]="bgVideo" type="video/mp4">
    Tu navegador no soporta la etiqueta de video.
  </video>
  <div class="video-overlay"></div>
  <ng-content></ng-content>
</div>

<ng-template #bgImageTemplate>
  <div id="{{ ids }}" data-parallax="true" [style.background]="'url(' + bgImage + ')'" class="{{ classNames }}">
    <ng-content></ng-content>
  </div>
</ng-template>
